@charset "utf-8";

@import "fonts.css";
@import "vendor/reset.css";
@import "vars.css";

html, body {
  height: 100%;
  width: 100%;
  min-width: 300px;
}

body {
  background: #fff;
  color: $default-color;
  font: 18px/30px $default-font-fallback;

  .fonts-loaded & {
    font-family: $default-font;
  }
}

a {
  text-decoration: none;
  color: $red;

  &:visited,
  &:active {
    color: $red;
  }

  &:hover {
    opacity: 0.8;
  }
}

h1 {
  font-size: 70px;
  line-height: 90px;
  letter-spacing: 2px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 22px;
}

.logo {
  display: flex;
  margin-bottom: 40px;
}

.logo svg {
  width: 240px;
  height: 180px;
  margin: 0 auto;
}

h2 {
  font-size: 40px;
  line-height: 50px;
  letter-spacing: 1.5px;
  margin: 30px 0 30px 0;
}

h4 {
  font-size: 14px;
  color: $grey-text;
  font-weight: normal;
}

blockquote {
  margin: $gap-s 0;
  padding: $gap-s 0;
  font-weight: bold;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;

  p {
    margin: 0;
  }
}

p {
  margin: 10px 0;
}

ul {
  margin-top: 20px;
}

input[type="checkbox"] {
  height: 18px;
}

input[type="checkbox"] + label {
  position: relative;
  min-height: 15px;
  padding-left: 14px;
  cursor: pointer;
  font-weight: normal;
  line-height: 15px;
  color: $martian-green;
  font-weight: bold;
}

pre {
  background: $code-bg;
  color: $code-color;
  margin-left: -10%;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 10%;
  width: 120%;
  font-feature-settings: "calt" 1;
  text-rendering: optimizeLegibility;
  white-space: pre-wrap;
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
}

.moto {
  margin: $gap-s 0;
  padding: $gap-s 0;
  font-weight: bold;
  color: $martian-green;
  width: 100%;
  font-size: 24px;
  text-align: center;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
}

.caption {
  font-size: 60%;
}

.mobile-only {
  display: none;
}

.grey-text {
  color: $grey-text;
}

.content {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 60px;
  box-sizing: border-box;
  width: 80%;
  overflow: hidden;
}

.nav {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 10px 20px;
  z-index: 100;
}

.nav--btn {
  cursor: pointer;
  height: 60px;
  text-decoration: none;
  padding: 0 20px;
  text-align: center;
  transition: opacity 200ms;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    opacity: .8;
    transition: none;
  }

  &:visited,
  &:hover,
  &:active {
    color: $red;
  }

  &--icon {
    margin-right: 16px;
    margin-top: 6px;
  }
}

.social-icons {
  width: 100%;
  text-align: center;
  padding: 20px 0;
}

@media (max-width: 800px) and (min-width: 601px) {
  body {
    font-size: 3vw;
    line-height: 5vw;
  }

  h1 {
    font-size: 12vw;
    line-height: 18vw;
  }

  h2 {
    font-size: 5vw;
    line-height: 7vw;
  }

  h4 {
    font-size: 2vw;
  }

  .moto {
    line-height: 7vw;
  }
}

@media (max-width: 800px) {
  .content {
    padding: 20px;
    width: 100%;
  }

  h1 {
    margin-bottom: 10px;
    margin-top: 50px;
  }

  pre {
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;
    width: calc(100% + 40px);
  }
}


@media (max-width: 600px) {
  body {
    font-size: 4vw;
    line-height: 6vw;
  }

  h1 {
    font-size: 12vw;
    line-height: 18vw;
  }

  h2 {
    font-size: 10vw;
    line-height: 12vw;
  }

  h4 {
    font-size: 3vw;
  }

  .logo {
    margin-bottom: 20px;
  }

  .logo svg {
    width: 160px;
    height: 120px;
  }

  pre {
    word-break: break-all;
  }

  .nav--btn {
    padding: 0;
  }

  .moto {
    line-height: 10vw;
  }

  .mobile-only {
    display:  block;
  }

  .desktop-only {
    display: none !important;
  }
}

@import "nested-list.css";
@import "footer.css";
@import "vendor/hljs-railscasts.css";
@import "vendor/likely.css"
