@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: "Stem Text";
  src: url("//cdn.evilmartians.com/front/fonts/subset-StemText-Regular.woff") format("woff");
}
@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: "Stem Text";
  src: url("//cdn.evilmartians.com/front/fonts/subset-StemText-Bold.woff") format("woff");
}

@font-face {
  font-family: "Fira Code";
  src: url("./fonts/FiraCode-Regular.woff") format("woff");
}
